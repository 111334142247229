import request from '@/utils/request'
// 获得常见问题列表
export function getQuestionList(lang) {
  return request({
    url: '/app-api/website/question/list',
    method: 'get'
  })
}
export function addDemand(data) {
  return request({
    url: '/app-api/website/demand/create',
    method: 'post',
    data: data
  })
}
// 获得招聘信息列表
export function getRecruitList(lang) {
  return request({
    url: '/app-api/website/recruit/list',
    method: 'get'
  })
}
// 获得招聘信息详情
export function getRecruitDetails(id) {
  return request({
    url: '/app-api/website/recruit/get?id=' + id,
    method: 'get'
  })
}
