<template>
  <div>
    <el-dialog :visible.sync="dialogVisible" :width="width" :show-close="false">
      <template #title>
        <div class="dialog-box flex" :style="cssVars" style="padding: 20px;gap: 16px;">
          <div class="iconfont icon-arco-notification-icon" style="font-size: 24px;"></div>
          <div>
            <div class="title" style="font-size: 16px;line-height: 24px;">
              {{ $t('common.successMsg.title') }}
            </div>
            <div class="desc" style="font-size: 14px;line-height: 22px;margin-top: 4px">
              This is a success Notification!
            </div>
          </div>
        </div>
      </template>
      <slot />
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: 'MsgDialog',
  data() {
    return {
      dialogVisible: false
    }
  },
  props: {
    width: {
      type: String,
      default: '340px'
    },
    top: {
      type: String,
      default: '25vh'
    }
  },
  computed: {
    cssVars() {
      return {
        '--width': this.width,
        '--top': this.top
      }
    }
  },
  methods: {
    show() {
      this.dialogVisible = true
      setTimeout(() => {
        this.dialogVisible = false
      }, 3000)
    }
  }
}
</script>

<style scoped lang="scss">
.dialog-box {
  width: var(--width);
  margin: 0 auto;
  margin-top: var(--top);
  color: #1d2129;
  box-sizing: border-box;

  .iconfont {
    color: #00b42a;
  }
  .title {
    font-weight: 600;
  }
  .desc {
    font-weight: 400;
    font-family: Inter;
  }
}
/deep/ .el-dialog__header {
  padding: 0;
}
/deep/ .el-dialog__body {
  padding: 0;
}
/deep/ .el-dialog {
  border-radius: 4px;
}
</style>
