<template>
  <div>
    <el-dialog
      :visible.sync="dialogVisible"
      :width="width"
      :before-close="handleClose"
      :show-close="false"
      ref="dialog"
    >
      <template #title>
        <div class="dialog-box" :style="cssVars">
          <div class="dialog-header flex row-between">
            <div class="dialog-title flex col-center">
              <img src="@/assets/images/logo-black.png" />
              <div class="youShe text">DD Tokyo</div>
            </div>
            <div class="iconfont icon-guanbi" @click="handleClose()"></div>
          </div>
        </div>
      </template>
      <slot />
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: 'DDialog',
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    background: {
      type: String,
      default: '#BBB290'
    },
    width: {
      type: String,
      default: '75vw'
    },
    top: {
      type: String,
      default: '10.3vh'
    }
  },
  computed: {
    cssVars() {
      return {
        '--width': this.width,
        '--background': this.background,
        '--top': this.top
      }
    }
  },
  methods: {
    toTop() {
      this.$nextTick(function() {
        if (this.$refs.dialog.$el) {
          this.$refs.dialog.$el.scrollTop = 0
        }
      })
    },
    handleClose() {
      this.$emit('close')
    }
  }
}
</script>

<style scoped lang="scss">
.dialog-box {
  width: var(--width);
  background: var(--background);
  margin: 0 auto;
  margin-top: var(--top);
  border-radius: 10px 10px 0 0;
  .dialog-header {
    .dialog-title {
      margin: 36px 0 0 70px;
      img {
        width: 38px;
        height: 40.496px;
      }
      .text {
        margin-left: 16px;
        color: #1a1919;
        font-size: 24px;
        font-weight: 400;
      }
    }
    .icon-guanbi {
      font-size: 34px;
      color: $bg-color;
      margin: 30px 30px 0 0;
      cursor: pointer;
    }
  }
}
/deep/ .el-dialog__header {
  padding: 0;
}
/deep/ .el-dialog__body {
  padding: 0;
  background: $primary-color;
  border-radius: 0 0 10px 10px;
}
/deep/.el-dialog {
  background: transparent;
}
</style>
