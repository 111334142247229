<template>
  <div class="form" :style="cssVars">
    <el-form
      ref="form"
      :model="form"
      :rules="rlusForm"
      :validate-on-rule-change="false"
      label-position="right"
      :label-width="lableWidth"
    >
      <div class="title" :style="cssVars">{{ $t('common.formMsg.baseInfo') }}</div>
      <el-form-item :label="$t('common.formMsg.name.label')" prop="name">
        <div style="width: 22.916667vw">
          <el-input
            ref="input"
            :style="cssVars"
            v-model="form.name"
            maxlength="20"
            :placeholder="$t('common.formMsg.name.placeholder')"
          />
        </div>
      </el-form-item>
      <el-form-item :label="$t('common.formMsg.email.label')" prop="email">
        <div style="width: 22.916667vw">
          <el-input
            ref="input"
            :style="cssVars"
            maxlength="30"
            v-model="form.email"
            :placeholder="$t('common.formMsg.email.placeholder')"
          />
        </div>
      </el-form-item>
      <el-form-item :label="$t('common.formMsg.tel.label')" prop="phone">
        <div style="width: 22.916667vw">
          <el-input
            ref="input"
            :style="cssVars"
            v-model="form.phone"
            :placeholder="$t('common.formMsg.tel.placeholder')"
          />
        </div>
      </el-form-item>
      <div class="title " :style="cssVars">{{ $t('common.formMsg.demand') }}</div>
      <el-form-item :label="$t('common.formMsg.address.label')" prop="departureOther">
        <div style="width: 22.916667vw">
          <el-radio-group v-model="form.departure" @input="handleRadio">
            <el-radio v-for="(item, index) in departureList" :key="index" :label="item.value">{{
              item.label
            }}</el-radio>
          </el-radio-group>
          <el-input
            ref="input"
            :style="cssVars"
            maxlength="30"
            v-model="form.departureOther"
            v-show="form.departure == departureList.length"
            :placeholder="$t('common.formMsg.address.placeholder')"
          />
        </div>
      </el-form-item>
      <el-form-item :label="$t('common.formMsg.expectedDate.label')" prop="expectedDate">
        <div style="width: 22.916667vw">
          <el-date-picker
            v-model="form.expectedDate"
            type="date"
            :clearable="false"
            :style="cssVars"
            style="width: 22.916667vw"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            :picker-options="pickerOptions"
            :placeholder="$t('common.formMsg.expectedDate.placeholder')"
          >
          </el-date-picker>
        </div>
      </el-form-item>
      <el-form-item :label="$t('common.formMsg.flight.label')" prop="bookingFlight">
        <div style="width: 22.916667vw">
          <el-input
            ref="input"
            :style="cssVars"
            maxlength="20"
            v-model="form.bookingFlight"
            :placeholder="$t('common.formMsg.flight.placeholder')"
          />
        </div>
      </el-form-item>
      <el-form-item :label="$t('common.formMsg.destination.label')" prop="destination">
        <div style="width: 22.916667vw">
          <el-input
            ref="input"
            :style="cssVars"
            :value="form.destination"
            maxlength="30"
            v-model="form.destination"
            :placeholder="$t('common.formMsg.destination.placeholder')"
          />
        </div>
      </el-form-item>
      <el-form-item :label="$t('common.formMsg.peopleNumber.label')" prop="peopleNumber">
        <div style="width: 22.916667vw">
          <el-input-number
            :placeholder="$t('common.formMsg.peopleNumber.placeholder')"
            :style="cssVars"
            style="width: 22.916667vw"
            v-model="form.peopleNumber"
            controls-position="right"
            :precision="0"
            :min="1"
            :max="9999"
          ></el-input-number>
        </div>
      </el-form-item>
      <el-form-item :label="$t('common.formMsg.luggageNumber.label')" prop="luggageNumber">
        <div style="width: 22.916667vw">
          <el-input-number
            style="width: 22.916667vw"
            :style="cssVars"
            :placeholder="$t('common.formMsg.luggageNumber.placeholder')"
            v-model="form.luggageNumber"
            controls-position="right"
            :precision="0"
            :min="1"
            :max="9999"
          ></el-input-number>
        </div>
      </el-form-item>
      <el-form-item :label="$t('common.formMsg.contactInformation.label')" prop="contactType">
        <div style="width: 22.916667vw">
          <el-radio-group v-model="form.contactType">
            <el-radio
              v-for="(dict, index) in firstContactList"
              :key="index"
              :label="parseInt(dict.value)"
              :value="parseInt(dict.value)"
              >{{ dict.label }}</el-radio
            >
          </el-radio-group>
        </div>
      </el-form-item>
      <el-form-item :label="$t('common.formMsg.pickUpService.label')" prop="airportPickupStatus">
        <div style="width: 22.916667vw">
          <el-radio-group v-model="form.airportPickupStatus">
            <el-radio
              v-for="(item, index) in airportServiceList"
              :key="index"
              :value="parseInt(item.value)"
              :label="parseInt(item.value)"
              >{{ item.label }}</el-radio
            >
          </el-radio-group>
          <div class="tips">※{{ $t('common.formMsg.pickUpService.placeholder') }}</div>
        </div>
      </el-form-item>
      <el-form-item :label="$t('common.formMsg.childSeat.label')" prop="childSeatStatus">
        <div style="width: 22.916667vw">
          <el-radio-group v-model="form.childSeatStatus">
            <el-radio
              v-for="(item, index) in childSeatsList"
              :value="parseInt(item.value)"
              :key="index"
              :label="parseInt(item.value)"
              >{{ item.label }}</el-radio
            >
          </el-radio-group>
        </div>
      </el-form-item>
      <el-form-item :label="$t('common.formMsg.language.label')" prop="languageStatus">
        <div style="width: 22.916667vw">
          <el-radio-group v-model="form.languageStatus">
            <el-radio
              v-for="(item, index) in enOrKoList"
              :key="index"
              :value="parseInt(item.value)"
              :label="parseInt(item.value)"
              >{{ item.label }}</el-radio
            >
          </el-radio-group>
        </div>
      </el-form-item>
      <el-form-item :label="$t('common.formMsg.remark.label')" prop="customerRemark">
        <div style="width: 22.916667vw">
          <el-input
            ref="input"
            :style="cssVars"
            type="textarea"
            maxlength="300"
            :value="form.customerRemark"
            v-model="form.customerRemark"
            rows="3"
            :placeholder="$t('common.formMsg.remark.placeholder')"
          />
        </div>
      </el-form-item>
      <div class="title " :style="cssVars">{{ $t('common.formMsg.addService') }}</div>
      <el-form-item :label="$t('common.formMsg.exclusiveService.label')" prop="specialServices">
        <div style="width: 22.916667vw;margin-top: -5px">
          <el-checkbox-group v-model="form.specialServices" @change="handlecheckBox">
            <el-checkbox
              v-for="(item, index) in servicesList"
              :value="parseInt(item.value)"
              :label="parseInt(item.value)"
              :key="index"
              >{{ item.label }}</el-checkbox
            >
          </el-checkbox-group>
        </div>
      </el-form-item>
      <el-form-item :label="$t('common.formMsg.detailService.label')" prop="requirement">
        <div style="width: 22.916667vw">
          <el-input
            class="innter-texteara"
            ref="input"
            :style="cssVars"
            type="textarea"
            maxlength="300"
            :value="form.requirement"
            v-model="form.requirement"
            rows="3"
            :placeholder="$t('common.formMsg.detailService.placeholder')"
          />
        </div>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import { getDicts } from '@/api/dict'

export default {
  name: 'ContactForm',
  props: {
    checkWidth: {
      type: String,
      default: '32.916667vw'
    },
    checkSpace: {
      type: String,
      default: 'nowrap'
    },
    lableWidth: {
      type: String,
      default: '18vw'
    },
    titleColor: {
      type: String,
      default: 'rgba(255, 255, 255, 0.90)'
    },
    backgroundColor: {
      type: String,
      default: '#161617'
    },
    isCheckRadioColor: {
      type: String,
      default: 'rgba(255, 255, 255, 0.90)'
    },
    isCheckRadioBgColor: {
      type: String,
      default: '#232324'
    },
    isCheckInnerRadioColor: {
      type: String,
      default: '#484849'
    },
    radioInnerColor: {
      type: String,
      default: '#232324'
    },
    inputBackColor: {
      type: String,
      default: 'rgba(255, 255, 255, 0.08)'
    },
    placeholderColor: {
      type: String,
      default: 'rgba(255, 255, 255, 0.50)'
    },
    tipsColor: {
      type: String,
      default: '#BBB290'
    },
    isCheckBack: {
      type: String,
      default: '#484849'
    },
    isCheckIcon: {
      type: String,
      default: '#fff'
    },
    noCheckBack: {
      type: String,
      default: '#232324'
    },
    noCheckBor: {
      type: String,
      default: '#484849'
    },
    isCheckLabel: {
      type: String,
      default: 'rgba(255, 255, 255, 0.9)'
    },
    isAfterCo: {
      type: String,
      default: '#fff'
    },
    checkLabel: {
      type: String,
      default: 'rgba(255, 255, 255, 0.90)'
    },
    formLabelColor: {
      type: String,
      default: 'rgba(255, 255, 255, 0.90)'
    },
    inputColor: {
      type: String,
      default: 'rgba(255, 255, 255, 0.90)'
    },
    inputColorPla: {
      type: String,
      default: '#939394'
    },
    dateColor: {
      type: String,
      default: 'rgba(255, 255, 255, 0.9)'
    },
    radioAfter: {
      type: String,
      default: 'rgba(255, 255, 255, 0.90)'
    }
  },
  data() {
    return {
      pickerOptions: {
        disabledDate(v) {
          return v.getTime() < new Date().getTime() - 86400000 //  - 86400000是否包括当天
        }
      },
      airportServiceList: [],
      childSeatsList: [],
      enOrKoList: [],
      firstContactList: [],
      departureList: [],
      servicesList: [],
      lang: '',
      form: {
        name: undefined, //姓名
        email: undefined, //邮箱
        phone: undefined, //手机号
        // customerRemark: undefined, // 留言
        bookingFlight: undefined, // 预定航班
        customerRemark: undefined, // 备注
        expectedDate: undefined, //期望日期
        destination: undefined, // 目的地
        peopleNumber: undefined, // 人数
        luggageNumber: undefined, // 行李数
        airportPickupStatus: undefined, // 接机服务
        childSeatStatus: undefined, // 儿童座椅
        languageStatus: undefined, // 英韩语
        contactType: undefined, // 首选联系方式
        departure: undefined, // 出发地
        departureOther: undefined, // 出发地
        requirement: undefined, // 具体要求
        specialServices: [], // 专享服务
        demandStatus: undefined // 状态
      }
    }
  },
  computed: {
    cssVars() {
      return {
        '--titleColor': this.titleColor,
        '--inputBackColor': this.inputBackColor,
        '--backgroundColor': this.backgroundColor,
        '--isCheckRadioColor': this.isCheckRadioColor,
        '--radioInnerColor': this.radioInnerColor,
        '--isCheckInnerRadioColor': this.isCheckInnerRadioColor,
        '--placeholderColor': this.placeholderColor,
        '--tipsColor': this.tipsColor,
        '--isCheckBack': this.isCheckBack,
        '--noCheckBack': this.noCheckBack,
        '--noCheckBor': this.noCheckBor,
        '--isCheckIcon': this.isCheckIcon,
        '--isCheckLabel': this.isCheckLabel,
        '--isAfterCo': this.isAfterCo,
        '--checkLabel': this.checkLabel,
        '--formLabelColor': this.formLabelColor,
        '--inputColor': this.inputColor,
        '--inputColorPla': this.inputColorPla,
        '--dateColor': this.dateColor,
        '--radioAfter': this.radioAfter,
        '--isCheckRadioBgColor': this.isCheckRadioBgColor
      }
    },
    rlusForm() {
      return {
        name: [{ required: true, message: this.$i18n.t('common.formMsg.rlus'), trigger: 'blur' }],
        email: [
          {
            required: true,
            message: this.$i18n.t('common.formMsg.email.rlus'),
            pattern: /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(?:\.[a-zA-Z0-9_-])+/,
            trigger: 'blur'
          }
        ],
        phone: [
          {
            required: true,
            message: this.$i18n.t('common.formMsg.tel.rlus'),
            pattern: /^\d{8,13}$/,
            trigger: 'blur'
          }
        ],
        expectedDate: [
          { required: true, message: this.$i18n.t('common.formMsg.rlusSelect'), trigger: 'blur' }
        ],
        destination: [
          { required: true, message: this.$i18n.t('common.formMsg.rlus'), trigger: 'blur' }
        ],
        peopleNumber: [
          { required: true, message: this.$i18n.t('common.formMsg.rlus'), trigger: 'blur' }
        ],
        luggageNumber: [
          { required: true, message: this.$i18n.t('common.formMsg.rlus'), trigger: 'blur' }
        ],
        airportPickupStatus: [
          { required: true, message: this.$i18n.t('common.formMsg.rlusSelect'), trigger: 'blur' }
        ],
        childSeatStatus: [
          { required: true, message: this.$i18n.t('common.formMsg.rlusSelect'), trigger: 'blur' }
        ],
        languageStatus: [
          { required: true, message: this.$i18n.t('common.formMsg.rlusSelect'), trigger: 'blur' }
        ],
        contactType: [
          { required: true, message: this.$i18n.t('common.formMsg.rlusSelect'), trigger: 'blur' }
        ],
        departureOther: [
          { required: true, message: this.$i18n.t('common.formMsg.rlus'), trigger: 'blur' }
        ]
      }
    }
  },
  watch: {
    '$i18n.locale'(newValue) {
      this.lang = newValue
      this.initDict()
      var inputElement = document.querySelector('.el-input__inner')
      var inputTeElement = document.querySelector('.el-textarea__inner')
      var inputTeElement1 = document.querySelector('.innter-texteara')
      // 设置输入框的字体家族
      if (newValue === 'zh') {
        inputElement.style.fontFamily = 'Source Han Mono SC'
        inputTeElement.style.fontFamily = 'Source Han Mono SC'
        inputTeElement1.style.fontFamily = 'Source Han Mono SC'
      } else if (newValue === 'ja') {
        inputElement.style.fontFamily = 'irohamaru'
        inputTeElement.style.fontFamily = 'irohamaru'
        inputTeElement1.style.fontFamily = 'irohamaru'
      } else if (newValue === 'zhf') {
        inputElement.style.fontFamily = 'Source Han Mono TC'
        inputTeElement.style.fontFamily = 'Source Han Mono TC'
        inputTeElement1.style.fontFamily = 'Source Han Mono TC'
      } else if (newValue === 'en') {
        inputElement.style.fontFamily = 'Inter'
        inputTeElement.style.fontFamily = 'Inter'
        inputTeElement1.style.fontFamily = 'Inter'
      }

      // this.$refs['form'].resetFields()
    }
  },

  mounted() {
    this.lang = localStorage.getItem('language') || 'jap'
    var inputElement = document.querySelector('.el-input__inner')
    var inputTeElement = document.querySelector('.el-textarea__inner')
    // 设置输入框的字体家族
    if (this.lang === 'zh') {
      inputElement.style.fontFamily = 'Source Han Mono SC'
      inputTeElement.style.fontFamily = 'Source Han Mono SC'
    } else if (this.lang === 'ja') {
      inputElement.style.fontFamily = 'irohamaru'
      inputTeElement.style.fontFamily = 'irohamaru'
    } else if (this.lang === 'zhf') {
      inputElement.style.fontFamily = 'Source Han Mono TC'
      inputTeElement.style.fontFamily = 'Source Han Mono TC'
    } else if (this.lang === 'en') {
      inputElement.style.fontFamily = 'Inter'
      inputTeElement.style.fontFamily = 'Inter'
    }
    this.initDict()
  },
  methods: {
    handlecheckBox() {
      var inputElement = document.querySelector('.el-input__inner')
      var inputTeElement = document.querySelector('.el-textarea__inner')
      // 设置输入框的字体家族
      if (this.lang === 'zh') {
        inputElement.style.fontFamily = 'Source Han Mono SC'
        inputTeElement.style.fontFamily = 'Source Han Mono SC'
      } else if (this.lang === 'ja') {
        inputElement.style.fontFamily = 'irohamaru'
        inputTeElement.style.fontFamily = 'irohamaru'
      } else if (this.lang === 'zhf') {
        inputElement.style.fontFamily = 'Source Han Mono TC'
        inputTeElement.style.fontFamily = 'Source Han Mono TC'
      } else if (this.lang === 'en') {
        inputElement.style.fontFamily = 'Inter'
        inputTeElement.style.fontFamily = 'Inter'
      }
    },
    initDict() {
      this.getDicts('website_contact_type')
      this.getDicts('website_airport_pickup_status')
      this.getDicts('website_child_seat_status')
      this.getDicts('website_language_status')
      this.getDicts('website_special_services')
      this.getDicts('website_departure')
    },
    handleRadio(e) {
      this.form.departure = e
      if (e != this.departureList.length) {
        this.form.departureOther = this.departureList[Number(e) - 1].label
      } else {
        this.form.departureOther = undefined
      }
    },
    reset() {
      this.$refs['form'].resetFields()
    },
    getFormData() {
      let flag = true
      this.$refs['form'].validate(valid => {
        flag = valid
      })
      if (flag) {
        return this.form
      } else {
        return false
      }
    },
    getDicts(type) {
      getDicts(type).then(res => {
        if (type === 'website_contact_type') {
          this.firstContactList = res.data
        }
        if (type === 'website_airport_pickup_status') {
          this.airportServiceList = res.data
        }
        if (type === 'website_child_seat_status') {
          this.childSeatsList = res.data
        }
        if (type === 'website_language_status') {
          this.enOrKoList = res.data
        }
        if (type === 'website_special_services') {
          this.servicesList = res.data
        }
        if (type === 'website_departure') {
          this.departureList = res.data
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.innter-texteara {
  font-family: irohamaru;
}
.custom-font {
  font-family: 'Your Custom Font', sans-serif;
}
.form {
  background-color: var(--backgroundColor);
  color: var(--titleColor);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .title {
    font-weight: bold;
    font-size: 22px;
    line-height: 22px;
    margin-bottom: 45px;
    margin-left: 18vw !important;
    width: 100%;
  }
  .tips {
    color: var(--tipsColor);
    font-size: 18px;
    font-weight: 400;
    line-height: 22px;
  }
}
::v-deep .el-form-item__label {
  margin-top: -2px;
}

::v-deep .el-textarea__inner {
  font-size: 18px !important;
  font-weight: 400;
  color: var(--inputColor);
  min-height: 80px !important;
}
::v-deep .el-input__inner::placeholder {
  color: var(--inputColorPla) !important;
}
/* 考虑到兼容的话就是*/
/* 谷歌 */
::v-deep .el-input__inner::-webkit-input-placeholder {
  color: var(--inputColorPla) !important;
}
/* 火狐 */
::v-deep .el-input__inner:-moz-placeholder {
  color: var(--inputColorPla) !important;
}
/*ie*/
::v-deep .el-input__inner:-ms-input-placeholder {
  color: var(--inputColorPla) !important;
}

::v-deep .el-form-item__label {
  font-size: 18px !important;
  color: var(--formLabelColor);
}
::v-deep .el-input__inner {
  font-size: 18px !important;
  font-weight: 400;
  color: var(--inputColor);
}
::v-deep .el-input--prefix .el-input__inner {
  padding-left: 0.78125vw !important;
}
::v-deep .el-form-item {
  margin-bottom: 40px;
}
::v-deep .el-form-item--medium .el-form-item__label {
  font-size: 18px;
  line-height: 22px;
  margin-top: 8px;
  color: var(--titleColor);
}
::v-deep .el-input--medium .el-input__inner {
  height: 36px;
  padding: 7px 16px;
  border-radius: 2px;
}
::v-deep .el-radio {
  margin-right: 16px;
}
::v-deep .el-radio__inner {
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  background: var(--radioInnerColor) !important;
  border: 2px solid var(--isCheckInnerRadioColor);
}
::v-deep .el-radio__input.is-checked .el-radio__inner {
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  border-color: var(--isCheckInnerRadioColor);
  background: var(--isCheckRadioBgColor) !important;
  //margin-top: -30px;
}
::v-deep .el-radio {
  margin-bottom: 20px;
}
::v-deep .el-radio__input {
  margin-top: -4px;
}
::v-deep .el-radio__input.is-checked + .el-radio__label {
  color: var(--isCheckRadioColor);
}
::v-deep .el-radio__label {
  color: var(--isCheckRadioColor);
  font-size: 18px;
  font-weight: 400;
  line-height: 22px; /* 122.222% */
  //vertical-align: middle;
  margin-top: -4px;
}
::v-deep .el-radio-group {
  margin-top: 6px;
}
::v-deep .el-input--medium .el-input__inner {
  border-radius: 2px;
  border: transparent;
  background: var(--inputBackColor);
}
::v-deep .el-input-number__decrease:hover,
.el-input-number__increase:hover {
  color: rgba(187, 178, 144, 0.6) !important;
}
::v-deep .el-input-number__decrease:hover,
.el-input-number__increase:hover {
  color: rgba(187, 178, 144, 0.6) !important;
}
::v-deep .el-input-number__decrease:hover,
::v-deep .el-input-number__increase:hover {
  color: rgba(187, 178, 144, 0.6) !important;
}
::v-deep .el-input-number.is-controls-right .el-input-number__increase {
  background: transparent;
  color: var(--dateColor);
}
::v-deep .el-input-number.is-controls-right .el-input-number__increase {
  border: transparent;
}
::v-deep .el-input-number.is-controls-right .el-input-number__decrease {
  border: transparent;
  background: transparent;
  color: var(--dateColor);
}
::v-deep .el-input__prefix {
  position: absolute;
  left: 410px;
}

::v-deep .el-textarea__inner,
/deep/ .el-input__inner {
  border: transparent;
  background: var(--inputBackColor);
  height: 36px;
  //font-family: irohamaru;
}

::v-deep .el-input-number .el-input__inner {
  text-align: start;
}
::v-deep .el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: var(--isCheckBack);
  border-color: var(--noCheckBor);
  display: flex;
  align-items: center;
  justify-content: center;
}
::v-deep .el-checkbox__inner {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--noCheckBack);
  border: 2px solid var(--noCheckBor);
  margin-top: -4px;
}
::v-deep .el-checkbox__input.is-focus .el-checkbox__inner {
  border-color: var(--noCheckBor) !important;
}
::v-deep .el-checkbox__input.is-checked + .el-checkbox__label {
  color: var(--isCheckLabel);
}
::v-deep .el-checkbox__inner::after {
  border: 2px solid var(--isAfterCo);
  margin-top: -1px;
  margin-left: -2px;
  border-left: 0;
  border-top: 0;
}
::v-deep .el-form-item__error {
  color: #f53f5f !important;
  font-size: 17px;
}
::v-deep .el-checkbox__label {
  color: var(--checkLabel);
  font-size: 18px;
  font-weight: 400;
  //margin-bottom: -10px;
  vertical-align: middle;
  margin-top: -4px;
  //line-height: 22px;
}
::v-deep .el-input__icon {
  color: var(--dateColor);
}
::v-deep .el-input__icon:hover {
  color: rgba(187, 178, 144, 0.6) !important;
}

::v-deep .el-form-item__label:before {
  color: #f53f5f !important;
  margin-right: 0.208333vw;
}
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-textarea__inner::-webkit-input-placeholder {
  color: var(--placeholderColor);
}

::v-deep .el-date-table td.current:not(.disabled) span {
  background-color: red !important;
}
::v-deep .el-date-table td.in-range div {
  background-color: #eaf4ec !important;
}

::v-deep .el-date-table td.today span {
  color: #1a1919 !important;
}
::v-deep .el-radio__inner::after {
  box-sizing: border-box;

  background-color: var(--radioAfter);
  width: 6px;
  height: 6px;
}
</style>
